var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    _vm.link ? "a" : "div",
    {
      tag: "component",
      staticClass: "card-link",
      class: "card-link--" + _vm.theme,
      attrs: { href: _vm.link }
    },
    [
      _c("div", { staticClass: "card-link__wrapper" }, [
        _vm.link && _vm.theme === "warning"
          ? _c(
              "svg",
              {
                staticClass: "card-link__icon",
                attrs: {
                  width: "40",
                  height: "40",
                  viewBox: "0 0 33 33",
                  fill: "none",
                  xmlns: "http://www.w3.org/2000/svg"
                }
              },
              [
                _c("path", {
                  attrs: {
                    "fill-rule": "evenodd",
                    "clip-rule": "evenodd",
                    d:
                      "M2.06628 16.0831C2.06628 7.88136 8.71513 1.23252 16.9169 1.23252C25.1187 1.23252 31.7675 7.88136 31.7675 16.0831C31.7675 24.2849 25.1187 30.9337 16.9169 30.9337C8.71513 30.9337 2.06628 24.2849 2.06628 16.0831ZM16.9169 0C8.03443 0 0.833764 7.20066 0.833764 16.0831C0.833764 24.9656 8.03443 32.1663 16.9169 32.1663C25.7994 32.1663 33 24.9656 33 16.0831C33 7.20066 25.7994 0 16.9169 0ZM14.1834 13.1255C13.6341 11.2944 15.0053 9.45156 16.917 9.45156C18.8287 9.45156 20.1998 11.2944 19.6505 13.1255L18.4751 17.0434C18.2687 17.7315 17.6354 18.2027 16.917 18.2027C16.1986 18.2027 15.5652 17.7315 15.3588 17.0434L14.1834 13.1255ZM16.917 10.6841C15.8309 10.6841 15.0519 11.7311 15.364 12.7713L16.5393 16.6892C16.5894 16.856 16.7429 16.9702 16.917 16.9702C17.0911 16.9702 17.2446 16.856 17.2946 16.6892L18.47 12.7713C18.7821 11.7311 18.0031 10.6841 16.917 10.6841ZM16.917 20.4585C16.4268 20.4585 16.0295 20.8558 16.0295 21.346C16.0295 21.8361 16.4268 22.2334 16.917 22.2334C17.4071 22.2334 17.8044 21.8361 17.8044 21.346C17.8044 20.8558 17.4071 20.4585 16.917 20.4585ZM14.797 21.346C14.797 20.1751 15.7461 19.226 16.917 19.226C18.0878 19.226 19.0369 20.1751 19.0369 21.346C19.0369 22.5168 18.0878 23.4659 16.917 23.4659C15.7461 23.4659 14.797 22.5168 14.797 21.346Z",
                    fill: "#0077C8"
                  }
                })
              ]
            )
          : _vm.link
          ? _c(
              "svg",
              {
                staticClass: "card-link__icon card-link__icon--right",
                attrs: {
                  version: "1.1",
                  viewBox: "0 0 32 32",
                  width: "40",
                  hidden40: ""
                }
              },
              [
                _c("path", {
                  attrs: {
                    pid: "0",
                    "fill-rule": "evenodd",
                    "clip-rule": "evenodd",
                    d:
                      "M16 1.2c8.174 0 14.8 6.626 14.8 14.8S24.174 30.8 16 30.8 1.2 24.174 1.2 16 7.826 1.2 16 1.2zM16 0c8.837 0 16 7.163 16 16s-7.163 16-16 16S0 24.837 0 16 7.163 0 16 0zm.498 10.175a.699.699 0 01.495.205l4.624 4.623a.7.7 0 11-.99.99l-3.428-3.428v7.557a.7.7 0 11-1.4 0v-7.557l-3.429 3.428a.7.7 0 01-.99-.99l4.621-4.621a.697.697 0 01.497-.207z",
                    fill: "#0077C8"
                  }
                })
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "card-link__content" }, [_vm._t("default")], 2)
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "card-link__image",
          style: _vm.image ? "background-image: url(" + _vm.image + ")" : ""
        },
        [!_vm.image ? _c("div", { staticClass: "card-link__logo" }) : _vm._e()]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-d3e2bba8", { render: render, staticRenderFns: staticRenderFns })
  }
}