function initVideo() {
    const video = Array.from(document.querySelectorAll('.js-video'))

    if (!video.length) {
        return
    }

    video.forEach(item => {
        const cover = item.querySelector('.video-conatiner__cover')
        const video = item.querySelector('.video-conatiner__video')

        if (!cover || !video) {
            return
        }
        cover.addEventListener('click', e => {
            item.classList.add('is-active')
            video.play()
        })
    });
}

initVideo()
