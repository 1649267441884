
import Vue from 'vue'

const container = document.getElementById('simple-slider')
const exists = typeof (container) != 'undefined' && container != null

import SimpleSlider from './SimpleSlider.vue'
import Slide from './Slide.vue'

if (exists) {
    new Vue({
        el: '#simple-slider',
        components: {
            SimpleSlider,
            Slide
        },
        data: {
            lang: getLang()
        }
    })
}