
import Vue from 'vue'

const container = document.getElementById('card-link')
const exists = typeof (container) != 'undefined' && container != null

import CardLink from './CardLink.vue'

if (exists) {
    new Vue({
        el: '#card-link',
        components: {
            CardLink
        },
        data: {
            lang: getLang()
        }
    })
}