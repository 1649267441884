<template>
    <div
        class="simple-slider"
        :class="[`simple-slider--${theme}`, { 'simple-slider--dynamic': isDynamic }]"
    >
        <div class="swiper-container simple-slider__container" ref="slider">
            <div class="swiper-wrapper">
                <slot></slot>
            </div>
        </div>
        <div class="simple-slider__controls" :class="controlsClassArray">
            <div class="simple-slider__nav">
                <button
                    v-if="showNavigation"
                    class="simple-slider__nav-btn simple-slider__nav-btn--prev"
                    ref="prev"
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 28 22"
                        fill="none"
                    >
                        <path
                            d="M10.8309 21L0.830871 11L10.8309 1"
                            stroke="#0077C8"
                            stroke-linecap="square"
                        />
                    </svg>
                </button>

                <div v-if="showPagination" class="simple-slider__pagination" ref="pagination" />

                <button
                    v-if="showNavigation"
                    class="simple-slider__nav-btn simple-slider__nav-btn--next"
                    ref="next"
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 28 22"
                        fill="none"
                        style="transform: rotate(180deg);"
                    >
                        <path
                            d="M10.8309 21L0.830871 11L10.8309 1"
                            stroke="#0077C8"
                            stroke-linecap="square"
                        />
                    </svg>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import Swiper from 'swiper/dist/js/swiper.js'
// import Swiper from 'swiper'

export default {
    name: 'simple-slider',
    props: {
        showPagination: {
            type: Boolean,
            default: true
        },
        showNavigation: {
            type: Boolean,
            default: true
        },
        slidesPerView: {
            type: [String, Number],
            default: 1
        },
        spaceBetween: {
            type: Number,
            default: 0
        },
        slidesPerGroup: {
            type: Number,
            default: 1
        },
        controlsPosition: {
            type: String,
            default: 'left',
            validator: (prop) => ['left', 'center', 'right'].includes(prop)
        },
        theme: {
            type: String,
            default: 'default',
            validator: (prop) => ['white', 'default', 'media'].includes(prop)
        },
        autoHeight: {
            type: Boolean
        },
        isDynamic: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            slider: undefined
        }
    },
    methods: {
        init() {
            let options = {
                observer: true,
                observeParents: true,
                autoHeight: this.autoHeight,
                nextButton: this.$refs.next,
                prevButton: this.$refs.prev,
                pagination: this.isDynamic && this.$refs.pagination,
                paginationClickable: true,
                // for new swiper version:
                // navigation: {
                //     prevEl: this.$refs.prev,
                //     nextEl: this.$refs.next
                // },
                // pagination: {
                //     clickable: true,
                //     el: this.$refs.pagination,
                //     dynamicBullets: this.isDynamic
                // },
                slidesPerView: this.slidesPerView,
                spaceBetween: this.spaceBetween,
                slidesPerGroup: this.slidesPerGroup,
                breakpoints: {
                    1024: {
                        slidesPerView: 1,
                        spaceBetween: 10,
                        slidesPerGroup: 1
                    }
                }
            }

            this.slider = new Swiper(this.$refs.slider, options)
        }
    },
    computed: {
        controlsClassArray() {
            let controlsClassArray = [
                {
                    'u-justify-content--start': this.controlsPosition === 'left',
                    'u-justify-content--end': this.controlsPosition === 'right',
                    'u-justify-content--center': this.controlsPosition === 'center'
                }
            ]

            return controlsClassArray
        }
    },
    mounted() {
        this.init()
    }
}
</script>
