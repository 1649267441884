var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "simple-slider",
      class: [
        "simple-slider--" + _vm.theme,
        { "simple-slider--dynamic": _vm.isDynamic }
      ]
    },
    [
      _c(
        "div",
        {
          ref: "slider",
          staticClass: "swiper-container simple-slider__container"
        },
        [_c("div", { staticClass: "swiper-wrapper" }, [_vm._t("default")], 2)]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "simple-slider__controls",
          class: _vm.controlsClassArray
        },
        [
          _c("div", { staticClass: "simple-slider__nav" }, [
            _vm.showNavigation
              ? _c(
                  "button",
                  {
                    ref: "prev",
                    staticClass:
                      "simple-slider__nav-btn simple-slider__nav-btn--prev"
                  },
                  [
                    _c(
                      "svg",
                      {
                        attrs: {
                          xmlns: "http://www.w3.org/2000/svg",
                          width: "24",
                          height: "24",
                          viewBox: "0 0 28 22",
                          fill: "none"
                        }
                      },
                      [
                        _c("path", {
                          attrs: {
                            d: "M10.8309 21L0.830871 11L10.8309 1",
                            stroke: "#0077C8",
                            "stroke-linecap": "square"
                          }
                        })
                      ]
                    )
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.showPagination
              ? _c("div", {
                  ref: "pagination",
                  staticClass: "simple-slider__pagination"
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.showNavigation
              ? _c(
                  "button",
                  {
                    ref: "next",
                    staticClass:
                      "simple-slider__nav-btn simple-slider__nav-btn--next"
                  },
                  [
                    _c(
                      "svg",
                      {
                        staticStyle: { transform: "rotate(180deg)" },
                        attrs: {
                          xmlns: "http://www.w3.org/2000/svg",
                          width: "24",
                          height: "24",
                          viewBox: "0 0 28 22",
                          fill: "none"
                        }
                      },
                      [
                        _c("path", {
                          attrs: {
                            d: "M10.8309 21L0.830871 11L10.8309 1",
                            stroke: "#0077C8",
                            "stroke-linecap": "square"
                          }
                        })
                      ]
                    )
                  ]
                )
              : _vm._e()
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-3d016790", { render: render, staticRenderFns: staticRenderFns })
  }
}