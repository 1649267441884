require('!modernizr!./.modernizrrc');
require('./polyfills.js');
require('apply!callback!./components.js');

// Sticky header
const doc = document.documentElement
const w = window

let prevScroll = w.scrollY || doc.scrollTop
let curScroll
let direction = 0
let prevDirection = 0

const header = document.querySelector('header')

const checkScroll = function () {
    // Find the direction of scroll
    // 0 - initial, 1 - up, 2 - down
    curScroll = w.scrollY || doc.scrollTop

    if (curScroll > prevScroll) {
        // scrolled up
        direction = 2
    } else if (curScroll < prevScroll) {
        // scrolled down
        direction = 1
    }

    if (direction !== prevDirection) {
        toggleHeader(direction, curScroll)
    }

    prevScroll = curScroll
}

const toggleHeader = function (direction, curScroll) {
    if (direction === 2 && curScroll > 144) {
        header.classList.remove('scroll-up')
        header.classList.add('scroll-down')
        prevDirection = direction
    } else if (direction === 1) {
        header.classList.remove('scroll-down')
        header.classList.add('scroll-up')
        prevDirection = direction
    }
}

window.addEventListener('scroll', checkScroll)
